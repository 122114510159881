<template>
  <v-container fluid class="scrollerPage">
    <div class="responsiveX topMed" no-gutters>
      <div class="titleText px-1" v-html="aboveTitleHTML" />
      <TheHomeSearch />
    </div>
    <div no-gutters class="responsiveX topLarge">
      <v-card elevation="0" rounded="0" class="pa-2 borderBlueBottom" v-if="$route.params.lang == 'en'" align="center"
        >Important note: Content in the know how section is currently only available in German language!</v-card
      >

      <TheHomeCarousel />
    </div>
  </v-container>
</template>

<script>
import TheHomeSearch from '@/components/forms/TheHomeSearch.vue';
import TheHomeCarousel from '@/components/forms/TheHomeCarousel.vue';
import bindingsTextbausteine from '@/store/bindingsTextbausteine';
import { loader } from '@/mixins/loader';
import { aboveTitle } from '@/main/titleconstants';

export default {
  components: {
    TheHomeSearch,
    TheHomeCarousel
  },
  mixins: [loader],
  props: {
    admin: {
      type: Boolean,
      default: () => false
    }
  },
  created() {
    this.load();
  },
  computed: {
    ...bindingsTextbausteine(),
    aboveTitleHTML() {
      return aboveTitle[this.$route.params.lang];
    },
    carouselTextItems() {
      const doc = new DOMParser().parseFromString(this.home_header, 'text/html');
      if (doc) {
        const paragraphs = doc.querySelectorAll('p');
        if (paragraphs) {
          const paragraphsArray = Array.from(paragraphs);
          if (paragraphsArray.length) {
            return paragraphsArray.map(p => p.innerText);
          }
        }
      }
      return [];
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.admin && this.$store.state.isDirty) {
      await this.$root.$confirm({
        title: 'Zurück ohne Speichern?',
        message: 'Bitte bestätigen oder verwerfen Sie Ihre Änderungen.'
      });
    } else {
      next();
    }
  }
};
</script>
<style>
.borderBlueBottom {
  border-bottom: 1px solid rgb(0, 90, 154);
}
.transparentBack {
  background: none;
}

.backgroundGradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 60%,
    rgba(255, 255, 255, 0.4) 100%
  );
}

div.titleText h1 {
  font-size: 22px;
  color: rgb(0, 90, 154);
  margin-top: 0px;
  margin-bottom: 10px;
}

div.titleText a {
  color: rgb(0, 90, 154);
  text-decoration: underline;
}
</style>
