import { Model } from '@vuex-orm/core';

export default class Glossar extends Model {
  static entity = 'glossar';
  static fields() {
    return {
      id: this.number(0).nullable(),
      titel_de: this.string('').nullable(),
      beschreibung_de: this.string('').nullable(),
      link: this.string('').nullable(),
      keywords_de: this.string('').nullable(),
      generiert: this.attr(undefined).nullable(),
      sieheauch: this.attr([]).nullable(),
      titel_en: this.string('').nullable(),
      beschreibung_en: this.string('').nullable(),
      keywords_en: this.string('').nullable()
    };
  }
}
