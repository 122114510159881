import Objekt from '@/models/objekt';
import Objektart from '@/models/objektart';
import Ort from '@/models/ort';
import Bezirk from '@/models/bezirk';
import Widmung from '@/models/widmung';
import Region from '@/models/region';
import TechnischeInfrastruktur from '@/models/technischeInfrastruktur';
import Kampagnen from '@/models/kampagnen';
import FMMatrix from '@/models/fm_matrix';
import Glossar from '@/models/glossar';
import store from '@/store';
import axios from 'axios';

let loadingPromise;

export const loader = {
  methods: {
    load() {
      if (!loadingPromise) {
        this.$root.$emit('loading', 'init-data');
        loadingPromise = Promise.all([
          getContact(),
          Objekt.api().get('/api/objekt', {
            dataTransformer: ({ data }) => {
              data.forEach(objekt => {
                objekt.geaendertam = new Date(objekt.geaendertam).getTime();
              });
              return data;
            }
          }),
          Objektart.api().get('/api/objektart'),
          Ort.api().get('/api/ort'),
          Bezirk.api().get('/api/bezirk'),
          Region.api().get('/api/region'),
          TechnischeInfrastruktur.api().get('/api/technischeInfrastruktur'),
          Widmung.api().get('/api/widmung'),
          Kampagnen.api().get('/api/kampagnen', {
            dataTransformer: ({ data }) => {
              data.forEach(kampagne => {
                kampagne.geaendertam = new Date(kampagne.geaendertam).getTime();
              });
              return data;
            }
          }),
          FMMatrix.api().get('/api/fm_matrix'),
          Glossar.api().get('/api/glossar')
        ]);
        loadingPromise
          .then(() => {
            this.$root.$emit('loaded', 'init-data');
            this.$store.commit('allLoaded');
          })
          .catch(e => {
            throw new Error('Database Sync Error: ' + e.message);
          });
      }
      return loadingPromise;
    },
    /**
     * re-loads data that is dependant on user data
     */
    reLoad(vm) {
      if (!loadingPromise) {
        // if no loading Promise is defined, no data has yet been loaded at all
        // the korrekt data will then be loaded at the initial load, when the data is needed
        return true;
      }
      vm.$root.$emit('loading', 'init-data');
      const reloadPromise = Promise.all([
        getContact(),
        Objekt.api().get('/api/objekt', {
          dataTransformer: ({ data }) => {
            data.forEach(objekt => {
              objekt.geaendertam = new Date(objekt.geaendertam).getTime();
            });
            return data;
          }
        })
      ]);
      reloadPromise
        .then(() => {
          vm.$root.$emit('loaded', 'init-data');
        })
        .catch(e => {
          throw new Error('Database Sync Error: ' + e.message);
        });
    }
  }
};

/**
 * if logged in, get the contact from crm
 * because of performance reason, this is seperate from the other requests.
 */
async function getContact() {
  const userdata = store.state.userdata;
  if (userdata?.id) {
    try {
      const response = await axios.get(`/api/contact/${userdata.id}`);
      await store.dispatch('contact', response.data);
    } catch (e) {
      await store.dispatch('contact', null);
    }
    return true;
  } else {
    await store.dispatch('contact', null);
    return true;
  }
}
