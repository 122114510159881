import { Database } from '@vuex-orm/core';
import Bezirk from '@/models/bezirk';
import Region from '@/models/region';
import Katastralgemeinde from '@/models/katastralgemeinde';
import Widmung from '@/models/widmung';
import Objekt from '@/models/objekt';
import Objektart from '@/models/objektart';
import Ort from '@/models/ort';
import Objektbild from '@/models/objektbild';
import Kampagnen from '@/models/kampagnen';
import TechnischeInfrastruktur from '@/models/technischeInfrastruktur';
import FMMatrix from '@/models/fm_matrix';
import Glossar from '@/models/glossar';
import Log from '@/models/log';

const database = new Database();

database.register(Bezirk);
database.register(Region);
database.register(Ort);
database.register(Katastralgemeinde);
database.register(Widmung);
database.register(Objektart);
database.register(Objekt);
database.register(Objektbild);
database.register(Kampagnen);
database.register(TechnischeInfrastruktur);
database.register(FMMatrix);
database.register(Glossar);
database.register(Log);

export default database;
