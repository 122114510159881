import Vue from 'vue';
import i18n from '../localization/i18n';
import VueRouter from 'vue-router';
import Login from '@/views/Login.vue';
import Home from '@/views/Home.vue';
import { loader } from '@/mixins/loader';
import { v4 as uuidv4 } from 'uuid';
import store from '@/store';
import Axios from 'axios';

Vue.use(VueRouter);

const allowedLanguages = ['de', 'en']; // Allowed languages for router check (else defaulting to de)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}/home`
  },
  {
    path: '*',
    redirect: `/${i18n.locale}/home`
  },
  {
    path: '/foerderkompass',
    redirect: '/de/foerderkompass'
  },
  {
    path: '/standortsuche',
    redirect: '/de/standortsuche'
  },
  {
    path: '/wissen',
    redirect: '/de/wissen'
  },
  {
    path: '/wissen/*',
    redirect: '/de/wissen/*'
  },
  {
    path: '/de/RE-USE_Brachflaechenrecycling',
    redirect: '/de/wissen/09592d7a-8ed5-4d5a-bd2a-5e395218b08d'
  },
  {
    path: '/:lang/home',
    name: 'home',
    component: Home,
    props: true,
    meta: {
      title: 'ecoplus Standortkompass - Startseite',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/:lang/foerderkompass/:foerderId?',
    name: 'foerderkompass',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheFoerderkompass.vue'),
    props: true,
    meta: {
      title: 'ecoplus Standortkompass - Förderungen',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/:lang/glossar/:glossarId?',
    name: 'glossar',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheGlossar.vue'),
    props: true,
    meta: {
      title: 'ecoplus Standortkompass - Förderungen',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/:lang/wissen',
    name: 'wissen',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheWissen.vue'),
    props: true,
    meta: {
      title: 'ecoplus Standortkompass - Wissen',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/:lang/wissen/:wissenId',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheWissenDetail.vue'),
    name: 'wissendetail',
    props: true,
    meta: {
      title: 'ecoplus Standortkompass - Wissen / Details',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/admin',
    redirect: 'de/admin/liste'
  },
  {
    path: '/:lang/admin',
    redirect: 'de/admin/liste'
  },
  {
    path: '/:lang/admin/liste',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Browse.vue'),
    name: 'browse',
    meta: {
      title: 'ecoplus Standortkompass ADMIN - Objekte',
      requiresAuth: true
    }
  },
  {
    path: '/:lang/admin/objekt/neu',
    redirect: () => `/de/admin/objekt/${uuidv4()}`
  },
  {
    path: '/:lang/admin/objekt/:objektId',
    name: 'admin-objekt',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Editor.vue'),
    props: true,
    meta: {
      title: 'ecoplus Standortkompass ADMIN - Objekteditor',
      requiresAuth: true
    }
  },
  {
    path: '/:lang/objekteditor/:objektId',
    name: 'edit-objekt',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/user/Editor.vue'),
    props: true,
    meta: {
      title: 'ecoplus Standortkompass - Objekteditor',
      requiresAuth: false
    }
  },
  {
    path: '/:lang/admin/benutzer',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Benutzer.vue'),
    meta: {
      title: 'ecoplus Standortkompass ADMIN - Benutzer',
      requiresAuth: true
    }
  },
  {
    path: '/:lang/admin/glossar',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Glossar.vue'),
    meta: {
      title: 'ecoplus Standortkompass ADMIN - Glossar',
      requiresAuth: true
    }
  },
  {
    path: '/:lang/admin/glossar/:id',
    name: 'glossar-edit',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/GlossarEdit.vue'),
    props: true,
    meta: {
      title: 'ecoplus Standortkompass ADMIN - Glossar - Edit',
      requiresAuth: true
    }
  },
  {
    path: '/:lang/admin/foerdermatrix',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Foerdermatrix.vue'),
    meta: {
      title: 'ecoplus Standortkompass ADMIN - Fördermatrix',
      requiresAuth: true
    }
  },
  {
    path: '/:lang/admin/foerdermatrix/:id',
    name: 'foerdermatrix-edit',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/FoerdermatrixEdit.vue'),
    props: true,
    meta: {
      title: 'ecoplus Standortkompass ADMIN - Fördermatrix - Edit',
      requiresAuth: true
    }
  },

  {
    path: '/:lang/admin/statistik',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Statistik.vue'),
    meta: {
      title: 'ecoplus Standortkompass ADMIN - Statistik',
      requiresAuth: true
    }
  },
  {
    path: '/statistik/panel',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Statistik.vue'),
    meta: {
      title: 'Statistik Panel',
      requiresAuth: false
    }
  },
  {
    path: '/:lang/admin/kampagnen',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Kampagnen.vue'),
    meta: {
      title: 'ecoplus Standortkompass - Kampagnen',
      requiresAuth: true
    }
  },
  {
    path: '/:lang/admin/kampagnen/neu',
    redirect: () => `/:lang/admin/kampagnen/${uuidv4()}`
  },
  {
    path: '/:lang/admin/kampagnen/:id',
    name: 'kampagnen-edit',
    props: true,
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/KampagnenEdit.vue'),
    meta: {
      title: 'ecoplus Standortkompass - Kampagnen',
      requiresAuth: true
    }
  },
  {
    path: '/:lang/standortsuche',
    name: 'standortsuche',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheStandortsuche.vue'),
    meta: {
      title: 'ecoplus Standortkompass - Standortsuche',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/:lang/meineimmobilien',
    name: 'meineimmobilien'
  },
  {
    path: '/:lang/standortsuche/:objektId',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheStandortsuche.vue'),
    name: 'detailansicht',
    props: true,
    meta: {
      title: 'ecoplus Standortkompass - Standortsuche',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/:lang/standortsammlung/:standortsammlungIds',
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/TheStandortsuche.vue'),
    name: 'standortsammlung',
    props: true,
    meta: {
      title: 'ecoplus Standortkompass - Standortsuche',
      requiresAuth: false // set to real boolean for header items
    }
  },
  {
    path: '/:lang/login',
    component: Login,
    name: 'login',
    meta: {
      title: 'ecoplus Standortkompass - Login'
    }
  },
  {
    path: '/:lang/logout',
    name: 'logout',
    meta: {
      title: 'ecoplus Standortkompass'
    },
    component: {
      async beforeRouteEnter(to, from, next) {
        try {
          await Axios.get('/api/logout');
          store.dispatch('userdata', null);
          store.dispatch('contact', null);
          // @ts-ignore
          await loader.methods.reLoad(store._vm); // reload user-specific data (contact, objekte)
          const path = store.state.pathAfterLogin
            ? `/${i18n.locale}/login?next=${store.state.pathAfterLogin}`
            : `/${i18n.locale}/home`;
          store.dispatch('pathAfterLogin', null);
          next(path);
        } catch (e) {
          next(`/${i18n.locale}/admin/liste`);
        }
      }
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

/**
 * always keep barrierefrei in route
 * clean up immo fav and property state
 */
router.beforeEach((to, from, next) => {
  if (
    to.name == 'standortsuche' &&
    from.name != 'detailansicht' &&
    from.name != 'standortsuche' &&
    from.name != 'edit-objekt'
  ) {
    store.dispatch('onlyProperties', false);
    store.dispatch('favsOnly', false);
  }

  if (!hasQueryParams(to) && hasQueryParams(from) && from.query.barrierefrei && to.path !== from.path) {
    const newQuery = Object.assign({}, to.query, { barrierefrei: true });
    next({ name: to.name, query: newQuery });
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  if (to.name == 'meineimmobilien') {
    store.dispatch('startOnlyProperties', true);
    next({ path: '/' + to.params.lang + '/standortsuche' });
  }

  if (!store.state.userdata) {
    try {
      const response = await Axios.get('/api/userdata');
      store.dispatch('userdata', response.data);
    } catch (err) {
      //
    }
  }
  // Language Switcher
  allowedLanguages.indexOf(to.params.lang) > -1 ? (i18n.locale = to.params.lang) : (i18n.locale = 'de');
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const errNext = { path: `/${i18n.locale}/login`, query: { next: location.pathname } };
    document.title = to.meta.title;
    next(store.state.userdata.userlevel > 0 ? undefined : errNext);
  } else {
    document.title = to.meta.title;
    next();
  }
});

export default router;
