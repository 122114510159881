import { createXYZ } from 'ol/tilegrid';
import Map from 'ol/Map';
import View from 'ol/View';
import { createAllLayers, stamenExtent } from './mapInstanceLayerDefinitions';

/**
 * creates the map instance, to be used as single vue instance property only.
 * @param {Object=} options optional ol map constructor options, e.g. pixelRatio
 * @returns {Map}
 */
export function createMapInstance(options = {}) {
  const mapOptions = options;
  mapOptions.layers = mapOptions.layers || createAllLayers();
  mapOptions.view = new View({
    resolutions: createXYZ({ tileSize: 512, maxZoom: 23, minZoom: 6 }).getResolutions(),
    minZoom: 6,
    padding: [0, 0, 0, 0],
    enableRotation: false,
    extent: stamenExtent
  });
  const map = new Map(mapOptions);

  return map;
}
