import { nl2br } from '@/utils/utils';
import { Model } from '@vuex-orm/core';

export default class Objekt extends Model {
  static entity = 'objekt';
  static fields() {
    return {
      msid: this.string(''),
      status: this.number(0).nullable(),
      objektnummer: this.string('').nullable(),
      objektname: this.string('').nullable(),
      ort: this.string('').nullable(),
      plz: this.string('').nullable(),
      strasse: this.string('').nullable(),
      lageerreichbarkeit: this.string('').nullable(),
      standortbeschreibung: this.attr([]).nullable(),
      kontakte: this.attr(undefined).nullable(),
      erstelltam: this.attr(undefined).nullable(),
      geaendertam: this.attr(undefined).nullable(),
      geaendertvon: this.number(0).nullable(),
      exportnachkomsis: this.boolean(true).nullable(),
      koordinatentyp: this.number(0).nullable(),
      grundstuecke: this.attr([]).nullable(),
      objektart: this.number(180620000).nullable(),
      hallenhoehe: this.number(0).nullable(),
      flaechenbezeichnung: this.string('').nullable(),
      flaecheinqm: this.number(0).nullable(),
      istteilbar: this.boolean(false).nullable(),
      kleinsteflaecheinqm: this.number(0).nullable(),
      istkaufobjekt: this.boolean(true).nullable(),
      istmietobjekt: this.boolean(true).nullable(),
      kaufpreis: this.number(0).nullable(),
      kaufpreisproqm: this.number(0).nullable(),
      mietpreis: this.number(0).nullable(),
      mietpreisproqm: this.number(0).nullable(),
      detailbeschreibung: this.string('').nullable(),
      internebeschreibung: this.string('').nullable(),
      versionsnummer: this.number(0).nullable(),
      widmungen: this.attr([]).nullable(),
      technischeinfrastruktur: this.attr([]).nullable(),
      verkehrsinfrastruktur: this.attr([]).nullable(),
      bilder: this.attr([]).nullable(),
      geometry: this.attr(undefined).nullable(),
      id: this.attr(undefined).nullable(),
      polygon: this.attr(undefined).nullable(),
      owner: this.number(0).nullable(),
      ecoplus: this.attr(undefined).nullable(),
      uebersetzung: this.attr({
        msid: '',
        objektname_en: '',
        detailbeschreibung_en: '',
        lageerreichbarkeit_en: '',
        ecoplusbeschreibung_en: ''
      }).nullable(),
      istabgelaufen: this.boolean(true).nullable(),
      ablaufdatum: this.attr(undefined).nullable()
    };
  }

  static beforeCreate(model) {
    model.detailbeschreibung = nl2br(model.detailbeschreibung);
  }
}
