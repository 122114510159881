import { Model } from '@vuex-orm/core';

export default class Widmung extends Model {
  static entity = 'widmung';
  static fields() {
    return {
      id: this.string(''),
      name_de: this.string(''),
      name_en: this.string('')
    };
  }
}
