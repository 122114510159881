//import Textbausteine from '@/models/textbausteine';

/**
 * @returns {Object} Object literal with computed properties
 * @this {import('vue').Component}
 */
export default () => {
  /*const computed = {};
  const fields = Textbausteine.fields();
  for (const field in fields) {
    computed[field] = {
      get() {
        const item = Textbausteine.query().first();
        return item ? item[field] : fields[field].value;
      },
      set(value) {
        this.$store.dispatch('isDirty', true);
        const item = Textbausteine.query().first();
        Textbausteine.update({
          data: {
            id: item.$id,
            [field]: value
          }
        });
      }
    };
  }

  return computed;*/
};
