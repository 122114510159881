/**
 * default values for filters
 */
export const defaultFilterValues = {
  selectedType: null,
  selectedFlaeche: [],
  selectedPreis: [],
  mieteOrKauf: null,
  selectedTag: null,
  ecoplusOnly: false,
  favsOnly: false,
  selectedStatus: [200000000, 180620004, 180620001, 180620002, 180620003]
};

/**
 * default data types corresponding to default values
 */
export const defaultTypes = {
  selectedType: Number,
  selectedFlaeche: [Number],
  selectedPreis: [Number],
  mieteOrKauf: String,
  selectedTag: Number,
  ecoplusOnly: Boolean,
  favsOnly: Boolean,
  selectedStatus: [Number]
};

export function decodeUriValueForKey(key, value) {
  if (value === undefined) {
    return defaultFilterValues[key];
  }
  const type = defaultTypes[key];
  if (Array.isArray(type)) {
    // dont try to parse empty strings
    if (!value || !value.length) {
      return [];
    }
    const arrayType = type[0];
    let decodedValue;
    if (arrayType === Number) {
      decodedValue = decodeURIComponent(value).split(',').map(Number);
    } else {
      decodedValue = decodeURIComponent(value).split(',');
    }
    return decodedValue;
  } else if (type === Number) {
    return Number(value);
  } else if (type === Boolean) {
    // correctly parses 'false' and '0'
    return !!JSON.parse(value);
  } else {
    return decodeURIComponent(value);
  }
}
