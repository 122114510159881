import { Model } from '@vuex-orm/core';

export default class Objektart extends Model {
  static entity = 'objektart';
  static fields() {
    return {
      id: this.number(null),
      name: this.string('')
    };
  }
}
