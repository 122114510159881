<template>
  <div class="map" ref="objektKarte" />
</template>

<script>
import 'ol/ol.css';
import Objekt from '@/models/objekt';
import { createMapInstance } from '@/main/mapInstance';
import objektSource from '@/main/objektSource';
import { createObjektLayer } from '@/main/mapInstanceLayerDefinitions';
import View from 'ol/View';
import { Icon, Style } from 'ol/style';
import { getFormattedCenterFromFeature } from '@/utils/formatters';

let map, objektLayer;

export default {
  props: {
    selectedobjects: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: () => '/img/defaultmarker.png'
    },
    updatetrigger: {
      type: Boolean,
      default: () => false
    },
    clickInteraction: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    selectedobjects() {
      this.updateFeatures();
    },
    updatetrigger() {
      map.updateSize();
    },
    icon() {
      this.updateStyle();
    }
  },

  components: {},
  data: () => ({}),
  computed: {
    objektListe() {
      return this.selectedobjects.join(',');
    },
    objekte: () => {
      return Objekt.all();
    }
  },
  created() {},
  mounted() {
    map = createMapInstance();
    map.setView(new View());
    map.setTarget(/** @type {HTMLElement} */ (this.$refs.objektKarte));
    map.updateSize();
    objektLayer = createObjektLayer();
    map.addLayer(objektLayer);
    map.on('pointermove', evt => {
      const hit = map.hasFeatureAtPixel(evt.pixel, {
        layerFilter: l => l === objektLayer
      });
      if (hit) {
        map.getTargetElement().style.cursor = 'pointer';
      } else {
        map.getTargetElement().style.cursor = '';
      }
    });
    map.on('click', evt => {
      const features = map.getFeaturesAtPixel(evt.pixel, {
        layerFilter: l => l === objektLayer
      });
      if (features?.length) {
        const params = this.$route.params;
        params.objektId = features[0].get('objektid');
        const query = {
          zoom: '15',
          center: getFormattedCenterFromFeature(features[0])
        };
        const routeData = this.$router.resolve({
          name: 'detailansicht',
          params: { objektId: features[0].get('$id') },
          query
        });
        window.open(routeData.href, '_blank');
      }
    });
    this.updateStyle();
    this.updateFeatures();
  },
  beforeDestroy() {
    this.cleanup();
  },
  beforeRouteLeave() {
    this.cleanup();
  },
  methods: {
    cleanup() {
      map = null;
      objektLayer = null;
    },
    updateStyle() {
      objektLayer.setStyle(
        new Style({
          image: new Icon({
            src: this.icon || '/img/defaultmarker.png',
            anchor: [0.5, 1],
            crossOrigin: null
          })
        })
      );
    },
    updateFeatures() {
      const allFeatures = objektSource.getFeatures();
      const selectedFeatures = allFeatures.filter(f => {
        return this.selectedobjects.includes(f.getId());
      });
      objektLayer.getSource().clear();
      if (selectedFeatures.length) {
        objektLayer.getSource().addFeatures(selectedFeatures.map(f => f.clone()));
        map.getView().fit(objektLayer.getSource().getExtent(), {
          padding: [70, 70, 70, 70],
          maxZoom: 17
        });
      }
    }
  }
};
</script>

<style scoped>
.map {
  height: 100%;
  background-color: gray;
  overflow: hidden;
}
</style>
