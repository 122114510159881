import { Model } from '@vuex-orm/core';

export default class Kampagnen extends Model {
  static entity = 'kampagnen';
  static primaryKey = 'id';
  static fields() {
    return {
      id: this.string(''),
      reihenfolge: this.number(0).nullable(),
      seitenhintergrund: this.string('').nullable(),
      header: this.string('').nullable(),
      headersub: this.string('').nullable(),
      textdetail: this.string('').nullable(),
      objekte: this.attr([]).nullable(),
      objekticon: this.string('').nullable(),
      modul0anzeige: this.boolean(false).nullable(),
      modul0textposition: this.string('rechts').nullable(),
      modul0bildbreite: this.number(2).nullable(),
      modul1typ: this.string('').nullable(),
      modul1text: this.string('').nullable(),
      modul1bild: this.string('').nullable(),
      modul1bildbreite: this.number(2).nullable(),
      modul1anzeige: this.boolean(false).nullable(),
      modul2typ: this.string('').nullable(),
      modul2text: this.string('').nullable(),
      modul2bild: this.string('').nullable(),
      modul2bildbreite: this.number(2).nullable(),
      modul2anzeige: this.boolean(false).nullable(),
      modul3typ: this.string('').nullable(),
      modul3text: this.string('').nullable(),
      modul3bild: this.string('').nullable(),
      modul3bildbreite: this.number(2).nullable(),
      modul3anzeige: this.boolean(false).nullable(),
      modul4typ: this.string('').nullable(),
      modul4text: this.string('').nullable(),
      modul4bild: this.string('').nullable(),
      modul4bildbreite: this.number(2).nullable(),
      modul4anzeige: this.boolean(false).nullable(),
      modul5typ: this.string('').nullable(),
      modul5text: this.string('').nullable(),
      modul5bild: this.string('').nullable(),
      modul5bildbreite: this.number(2).nullable(),
      modul5anzeige: this.boolean(false).nullable(),
      modul6typ: this.string('').nullable(),
      modul6text: this.string('').nullable(),
      modul6bild: this.string('').nullable(),
      modul6bildbreite: this.number(2).nullable(),
      modul6anzeige: this.boolean(false).nullable(),
      modulsort: this.attr(['K', '1', '2', '3', '4', '5', 'V', 'N', 'D', '6']).nullable(),
      veranstaltungentext: this.string('').nullable(),
      veranstaltungenanzeige: this.boolean(false).nullable(),
      newstext: this.string('').nullable(),
      newsanzeige: this.boolean(false).nullable(),
      downloadstext: this.string('').nullable(),
      downloadsanzeige: this.boolean(false).nullable(),
      poolbilder: this.attr([]).nullable(),
      poolpdf: this.attr([]).nullable(),
      aktiv: this.boolean(false).nullable(),
      titelseite: this.boolean(false).nullable(),
      modulwissen: this.string('1'),
      modultitel: this.string('1'),
      routerurl: this.string('').nullable(),
      metatitle: this.string('').nullable(),
      metadescription: this.string('').nullable(),
      updatedAt: this.string('').nullable(),
      createdAt: this.string('').nullable()
    };
  }
}
