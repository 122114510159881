import { Model } from '@vuex-orm/core';

export default class Log extends Model {
  static entity = 'log';
  static fields() {
    return {
      logtime: this.attr(undefined).nullable(),
      userid: this.number(0).nullable(),
      ip: this.string('').nullable(),
      action: this.string('').nullable(),
      data1: this.string('').nullable(),
      data2: this.string('').nullable(),
      data3: this.string('').nullable(),
      id: this.number(0).nullable()
    };
  }
}
