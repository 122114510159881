import Vue from 'vue';
import VueI18n from 'vue-i18n';

import de from './locales/de.json';
import en from './locales/en.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'de', // set locale
  messages: { de, en }
});
