import Feature from 'ol/Feature';
import WKT from 'ol/format/WKT';
import JSONFeatureFormat from 'ol/format/JSONFeature';
import { get } from 'ol/proj';
import { GeometryCollection } from 'ol/geom';

class ObjektFormat extends JSONFeatureFormat {
  static wkt = new WKT();

  /**
   * @param {*} object
   * @param {import("ol/format/Feature").ReadOptions=} options
   * @returns {import("ol/Feature").default}
   */
  readFeatureFromObject(object, options) {
    const geometries = [];
    if (object.polygon) {
      geometries.push(ObjektFormat.wkt.readGeometry(object.polygon, options));
    }
    if (object.geometry) {
      geometries.push(
        // point geometry
        ObjektFormat.wkt.readGeometry(object.geometry, options)
      );
    }
    const geometry = geometries.length === 0 ? null : new GeometryCollection(geometries);
    const feature = new Feature({ ...object, geometry });
    feature.setId(object.id);
    return feature;
  }

  /**
   * @param {*} object
   * @param {import("ol/format/Feature").ReadOptions=} options
   * @returns {Array<import("ol/Feature").default>}
   */
  readFeaturesFromObject(object, options) {
    const length = object.length;
    const features = new Array(length);
    for (let i = 0; i < length; i++) {
      features[i] = this.readFeatureFromObject(object[i], options);
    }
    return features;
  }

  /**
   * @return {import("ol/proj/Projection").default} Projection
   */
  readProjectionFromObject() {
    return get('EPSG:4326');
  }
}

export default ObjektFormat;
