import { Model } from '@vuex-orm/core';

export default class Objektbild extends Model {
  static entity = 'objektbild';
  static primaryKey = 'msid';
  static fields() {
    return {
      msid: this.string(''),
      mimetype: this.string('').nullable(),
      crmfilename: this.string('').nullable(),
      s3filename: this.string('').nullable(),
      id: this.number(0).nullable(),
      width: this.number(0).nullable(),
      height: this.number(0).nullable()
    };
  }
}
