import { Model } from '@vuex-orm/core';

export default class Katastralgemeinde extends Model {
  static entity = 'katastralgemeinde';
  static fields() {
    return {
      id: this.number(null),
      name: this.string(''),
      bezirk: this.number(0),
      politischegemeinde: this.number(0)
    };
  }
}
