import { objektNameKey } from '@/main/constants';

/**
 * PHP's nl2br equivalent in JS
 * @param {string} str input text
 */
export function nl2br(str) {
  if (typeof str === 'undefined' || str === null) {
    return '';
  }
  const breakTag = '<br />';
  return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
}

/**
 * returns the full url for a given image link
 * images starting with "/img/" are local images
 * @param {string} image
 * @param {number} size size, defaults to 240 (icon size). Other Options:
 * 0: original size
 * 1920: large size
 * @returns {string}
 */
export function getUrlForImage(image, size = 240) {
  if (!image) {
    return null;
  }
  if (image.indexOf('/img/') === 0) {
    return image;
  } else {
    return `${import.meta.env.VITE_S3_BASE}/bilder/${size}/${image}`;
  }
}

/**
 *
 * @param {string} mimeType mime type (e.g. 'image/jpeg')
 * @returns {string} file ending
 */
export function getEndingForMimeType(mimeType) {
  if (mimeType === 'image/jpeg') {
    return 'jpg';
  } else if (mimeType === 'image/png') {
    return 'png';
  } else {
    return '';
  }
}

/**
 * gets the content of a cookie by name
 * @param {string} name
 * @returns
 */
export function getCookie(name) {
  const nameEQ = name + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

/**
 *
 * @param {string} flaechenbezeichnung
 * @param {number} objektart
 * @param {*} vm vue instance
 * @returns {string}
 */
export function translateFlaechenbezeichnung(flaechenbezeichnung, objektart, vm) {
  const language = vm.$i18n.locale;
  if (language !== 'de') {
    Object.values(objektNameKey).forEach(v => {
      if (flaechenbezeichnung.includes(v)) {
        flaechenbezeichnung = flaechenbezeichnung.replace(v, vm.$t(`expose.${objektart}`));
      }
    });
  }
  return flaechenbezeichnung;
}
