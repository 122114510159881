import Vue from 'vue';
import Vuex from 'vuex';
import VuexORM from '@vuex-orm/core';
import database from '@/database';
import VuexORMAxios from '@vuex-orm/plugin-axios';
import axios from 'axios';
import { defaultFilterValues } from './defaultValues';

Vue.use(Vuex);
VuexORM.use(VuexORMAxios, { axios });

const defaultValues = Object.assign({}, defaultFilterValues);

export default new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {
    baseLoaded: false,
    loading: false, // boolean, for generic full-page loading spinner
    isDirty: false,
    userdata: null,
    contact: null, // specified contact for the user (if logged in and defined by CRM)
    selectedType: defaultValues.selectedType,
    selectedTag: defaultValues.selectedTag, // tag selected via chips
    selectedFlaeche: defaultValues.selectedFlaeche,
    selectedPreis: defaultValues.selectedPreis,
    mieteOrKauf: defaultValues.mieteOrKauf,
    ecoplusOnly: defaultValues.ecoplusOnly,
    favsOnly: defaultValues.favsOnly,
    ignoreExtent: false, // when set, objekt filter ignores current map extent
    onlyProperties: false,
    startOnlyProperties: false,
    selectedStatus: defaultValues.selectedStatus,
    ecoplusdata: null,
    pathAfterLogin: null,
    missingTranslations: false
  },
  mutations: {
    missingTranslations(state, missingTranslations) {
      state.missingTranslations = missingTranslations;
    },
    allLoaded(state) {
      state.baseLoaded = true;
    },
    loading: (state, loading) => {
      state.loading = loading;
    },
    isDirty: (state, dirty) => {
      state.isDirty = dirty;
    },
    contact: (state, contact) => {
      state.contact = contact;
    },
    ecoplusdata: (state, ecoplusdata) => {
      state.ecoplusdata = ecoplusdata;
    },
    userdata: (state, userdata) => {
      state.userdata = userdata;
    },
    selectedTag: (state, selectedTag) => {
      state.selectedTag = selectedTag;
    },
    selectedType: (state, selectedType) => {
      state.selectedType = selectedType;
    },
    selectedFlaeche: (state, selectedFlaeche) => {
      state.selectedFlaeche = selectedFlaeche;
    },
    selectedPreis: (state, selectedPreis) => {
      state.selectedPreis = selectedPreis;
    },
    mieteOrKauf: (state, mieteOrKauf) => {
      state.mieteOrKauf = mieteOrKauf;
    },
    ecoplusOnly: (state, ecoplusOnly) => {
      state.ecoplusOnly = ecoplusOnly;
    },
    favsOnly: (state, favsOnly) => {
      state.favsOnly = favsOnly;
    },
    ignoreExtent: (state, ignoreExtent) => {
      state.ignoreExtent = ignoreExtent;
    },
    onlyProperties: (state, onlyProperties) => {
      state.onlyProperties = onlyProperties;
    },
    startOnlyProperties: (state, startOnlyProperties) => {
      state.startOnlyProperties = startOnlyProperties;
    },
    selectedStatus: (state, value) => {
      state.selectedStatus = value;
    },
    pathAfterLogin: (state, path) => {
      state.pathAfterLogin = path;
    }
  },
  actions: {
    missingTranslations: ({ commit }, missingTranslations) => {
      commit('missingTranslations', missingTranslations);
    },
    loading: ({ commit }, loading) => {
      commit('loading', loading);
    },
    isDirty: ({ commit }, dirty) => {
      commit('isDirty', dirty);
    },
    userdata: ({ commit }, userdata) => {
      commit('userdata', userdata);
    },
    ecoplusdata: ({ commit }, ecoplusdata) => {
      commit('ecoplusdata', ecoplusdata);
    },
    contact: ({ commit }, contact) => {
      commit('contact', contact);
    },
    pathAfterLogin: ({ commit }, path) => {
      commit('pathAfterLogin', path);
    },
    selectedFlaeche: function ({ commit }, selectedFlaeche) {
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
      commit('selectedFlaeche', selectedFlaeche);
    },
    selectedPreis: function ({ commit }, selectedPreis) {
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
      commit('selectedPreis', selectedPreis);
    },
    mieteOrKauf: function ({ commit }, mieteOrKauf) {
      // https://stackoverflow.com/questions/60491988/how-to-access-vue-instance-in-vuex
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
      commit('mieteOrKauf', mieteOrKauf);
    },
    selectedStatus: function ({ commit }, value) {
      commit('selectedStatus', value);
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
    },
    ecoplusOnly: function ({ commit }, ecoplusOnly) {
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
      commit('ecoplusOnly', ecoplusOnly);
    },
    selectedTag: function ({ commit }, selectedTag) {
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
      commit('selectedTag', selectedTag);
    },
    selectedType: function ({ commit }, selectedType) {
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
      commit('selectedType', selectedType);
    },
    favsOnly: function ({ commit }, favsOnly) {
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
      commit('favsOnly', favsOnly);
    },
    ignoreExtent: function ({ commit }, ignoreExtent) {
      commit('ignoreExtent', ignoreExtent);
    },
    onlyProperties: function ({ commit }, onlyProperties) {
      //@ts-ignore
      refreshObjektLayer(this._vm.$map);
      commit('onlyProperties', onlyProperties);
    },
    startOnlyProperties: function ({ commit }, startOnlyProperties) {
      commit('startOnlyProperties', startOnlyProperties);
    }
  },
  getters: {
    baseLoaded: state => state.baseLoaded
  }
});

let lastRefreshTimeStamp = 0;

function refreshObjektLayer(map) {
  const now = Date.now();
  if (now - lastRefreshTimeStamp > 100) {
    lastRefreshTimeStamp = now;
    const objektLayer = map
      .getLayers()
      .getArray()
      .find(l => l.get('name') === 'objektLayer');
    if (objektLayer) {
      objektLayer.changed();
    }
  }
}
