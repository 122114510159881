import { asArray } from 'ol/color';

export const ciColors = {
  blue: 'rgb(0, 115, 181)',
  yellow: 'rgb(255,213,0)',
  grey: '#ccc',
  darkGrey: '#111',
  Buero: 'rgb(0,120,170)',
  Gewerbe: 'rgb(190,0,0)',
  Grundstueck: 'rgb(60,160,0)'
};

// 180620000 = Grundstück
// 180620001 = Bürofläche
// 180620002 = Gewerbeobjekt
export const objektFarben = {
  180620000: ciColors['Grundstueck'],
  180620001: ciColors['Buero'],
  180620002: ciColors['Gewerbe']
};

export const objektNameKey = {
  180620000: 'Grundstück',
  180620001: 'Büro',
  180620002: 'Gewerbeobjekt'
};

export const statusFarben = {
  100000000: 'orange darken-1',
  180620000: 'grey',
  180620001: 'grey',
  180620002: 'light-green darken-4',
  180620003: 'light-green darken-3',
  180620004: 'blue-grey',
  200000000: 'amber darken-4'
};

export const statusItems = [
  { value: 180620000, text: 'Nicht anzeigen' },
  { value: 180620001, text: 'Entwurf' },
  { value: 180620002, text: 'Anzeigen mit Login' },
  { value: 180620003, text: 'Anzeigen ohne Login' },
  { value: 180620004, text: 'Deaktiviert' },
  { value: 200000000, text: 'In Begutachtung' }
];

// https://css-tricks.com/converting-color-spaces-in-javascript/
export function getHexColor(color) {
  if (color[0] === '#') {
    return color;
  } else {
    // Choose correct separator
    const sep = color.indexOf(',') > -1 ? ',' : ' ';
    // Turn "rgb(r,g,b)" into [r,g,b]
    color = color.substr(4).split(')')[0].split(sep);

    let r = (+color[0]).toString(16),
      g = (+color[1]).toString(16),
      b = (+color[2]).toString(16);

    if (r.length == 1) {
      r = '0' + r;
    }
    if (g.length == 1) {
      g = '0' + g;
    }
    if (b.length == 1) {
      b = '0' + b;
    }

    return '#' + r + g + b;
  }
}

export const fillColors = Object.assign({}, objektFarben);
for (const key in fillColors) {
  const color = asArray(fillColors[key]);
  color[3] = 0.2;
  fillColors[key] = color;
}

// 180620000 = Grundstück
// 180620001 = Bürofläche
// 180620002 = Gewerbeobjekt
export const objektIcons = {
  180620002: 'gewerbe.png',
  180620001: 'buero.png',
  180620000: 'grundstueck.png'
};

/**
 * resolution for:
 * switch between Basemap / Orthofoto
 * display objekt bounderies
 */
export const switchResolution = 2.42;

// extent taken from noe borders
export const wgsNoeExtent = '14.45423578,47.42218427,17.06882327,49.02045613000004';

/**
 * default ecoplus contact to display in the PDF and the "Kontakt Ecoplus" page,
 * if no other ecoplus-contact is defined in CRM
 */
export const defaultContact = {
  name: 'ecoplus. Niederösterreichs Wirtschaftsagentur GmbH',
  anschrift: 'Niederösterreichring 2, Haus A',
  plz: '3100',
  ort: 'St. Pölten, Österreich',
  telefon: '+43 2742 9000-19731',
  email: 'standort.service@ecoplus.at',
  website: 'www.ecoplus.at/investorenservice'
};
