<template>
  <div class="searchHolder">
    <v-row no-gutters>
      <TheSearch :objektClicked="objektClicked" />
    </v-row>
  </div>
</template>

<script>
import TheSearch from '../map/TheSearch.vue';
import { getFormattedCenterFromFeature } from '@/utils/formatters';
import objektSource from '@/main/objektSource';
import Objekt from '@/models/objekt';
/**
 * Objekt Details
 */
export default {
  components: { TheSearch },
  props: {},
  data: () => ({
    searchString: '',
    results: ['Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum']
  }),
  computed: {},
  methods: {
    objektClicked(objektId, objektStatus) {
      if ((!this.$store.state.userdata || !this.$store.state.userdata.id) && objektStatus == 180620002) {
        this.$root.$confirm({
          title: this.$t('error.greyobject.title').toString(),
          message: this.$t('error.greyobject.html').toString(),
          okonly: true,
          appearance: 'success'
        });
        return;
      }
      const feature = objektSource.getFeatureById(objektId);
      const center = getFormattedCenterFromFeature(feature);
      const newQuery = { zoom: '15', center };
      const objektQuery = Object.assign(this.$router.currentRoute.query, newQuery);
      this.goToObjekt(objektId, objektQuery);
    },
    goToObjekt(targetId, query) {
      const targetObjekt = Objekt.find(targetId);
      if (
        (!targetObjekt || !this.$store.state.userdata || !this.$store.state.userdata.id) &&
        //@ts-ignore
        targetObjekt.status == 180620002
      ) {
        this.$root.$confirm({
          title: this.$t('error.greyobject.title').toString(),
          message: this.$t('error.greyobject.html').toString(),
          okonly: true,
          appearance: 'success'
        });
        return;
      }

      const params = this.$route.params;
      params.objektId = targetId.toString();
      this.$router.push({ name: 'detailansicht', params, query }).catch(() => {});
    }
  }
};
</script>

<style scoped>
.searchHolder {
  width: 100%;
}
</style>
