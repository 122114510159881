export const tags = [
  { de: 'Objektart', en: 'Type of building', header: true },
  { id: 10, de: 'Lagerhalle', en: 'warehouse', header: false },
  { id: 11, de: 'Produktionshalle', en: 'Production hall', header: false },
  { id: 12, de: 'Labor', en: 'Laboratory', header: false },
  { de: 'Mobilität', en: 'Transport', header: true },
  { id: 20, de: 'Umfeld Bahnhof (unter 1km)', en: 'surrounding area railway station (within 1km)', header: false },
  { id: 21, de: 'Radweg/radfahrfreundlich', en: 'cycle lane', header: false },
  { id: 22, de: 'Bushaltestelle (unter 500m)', en: 'bus stop (within 500m)', header: false },
  { id: 23, de: 'e-Ladestation', en: 'e-charging station for vehicles', header: false },
  { id: 24, de: 'konfliktfreie Autobahnzufahrt/ohne Ortsdurchfahrt', en: 'direct highway access', header: false },
  { de: 'Objekteigenschaften / Ausstattung', en: 'Object characteristics/facilities', header: true },
  { id: 30, de: 'Werkstatt', en: 'workshop', header: false },
  { id: 31, de: 'Betreuter Gewerbepark/Business Park', en: 'serviced business park', header: false },
  { id: 32, de: 'Brownfield', en: 'brownfield', header: false },
  { id: 33, de: 'Erstbezug', en: 'first-time occupancy', header: false },
  { id: 34, de: 'ohne neuen Bodenverbrauch', en: 'no additional land consumption', header: false },
  {
    id: 35,
    de: 'klimafit (Gebäudeausstattung/Infrastruktur)',
    en: 'climate-friendly (facilities/infrastructure)',
    header: false,
    displayName: {
      de: 'klimafit',
      en: 'climate-friendly'
    }
  },
  { id: 36, de: 'Coworking', en: 'coworking', header: false },
  { id: 37, de: 'Besprechungsräume', en: 'conference rooms / meeting rooms', header: false },
  { id: 38, de: 'Kantine', en: 'cafeteria', header: false },
  { id: 39, de: 'Kran', en: 'crane system', header: false },
  { id: 40, de: 'Werkstattgrube', en: 'workshop pit', header: false },
  { id: 41, de: 'Mietservice / Mietgeräte', en: 'rental service/ rental gear', header: false },
  { id: 42, de: 'Umfeld (weniger als 1km)', en: 'surrounding area (within 1km)', header: true },
  { id: 43, de: 'Restaurants', en: 'restaurants', header: false },
  { id: 44, de: 'Supermärkte', en: 'supermarkets', header: false },
  { de: 'Technische Infrastruktur', en: 'technical infrastructure', header: true },
  { id: 50, de: 'Glasfaser', en: 'Fibre optic line', header: false },
  { id: 51, de: 'Photovoltaik', en: 'photovoltaic system' }
];
