import { Model } from '@vuex-orm/core';

export default class TechnischeInfrastruktur extends Model {
  static entity = 'technischeInfrastruktur';
  static fields() {
    return {
      id: this.string(''),
      name: this.string('')
    };
  }
}
