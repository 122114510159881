<template>
  <div class="fullWidth">
    <v-text-field
      ref="textField"
      v-model="searchString"
      class="searchPlaces"
      :placeholder="
        $router.currentRoute.name === 'home'
          ? $t('label.iSearch')
          : `${$t('label.search')} / ${$t('objekt.objektNumber')}`
      "
      hide-details
      append-icon="mdi-magnify"
      @click:append="goToFirst"
      @keydown.enter="goToFirst"
      @keyup="updateSearchString"
      @click:clear="clear"
      single-line
      clearable
      outlined
      dense
      :loading="loadingPhotonData"
    />
    <v-expand-transition>
      <v-sheet v-if="results.length" class="resultBox px-4">
        <div class="resultInnerBox">
          <v-row v-for="(result, index) in results" :key="index" :class="index === 0 ? 'mt-3' : ''">
            <v-card v-if="result.error" class="pa-1 pl-3 transparent" text left flat>
              {{ result.error }}
            </v-card>
            <v-hover v-else v-slot="{ hover }">
              <v-card
                class="pa-1 pl-3 d-flex"
                width="100%"
                text
                left
                flat
                :elevation="hover ? 4 : 0"
                :class="{ raised: hover }"
                @click="goToResult(result)"
              >
                <v-icon class="mr-2">{{ getIconForType(result.type) }}</v-icon>
                <span style="white-space: normal">
                  {{ getNameForResult(result) }}
                </span>
              </v-card>
            </v-hover>
          </v-row>
        </div>
      </v-sheet>
    </v-expand-transition>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import Axios from 'axios';
import { applyTransform, boundingExtent } from 'ol/extent';
import { getTransform } from 'ol/proj';
import Kampagnen from '@/models/kampagnen';
import router from '@/router';
import Objekt from '@/models/objekt';
import { getCenter } from 'ol/extent';
import FMMatrix from '@/models/fm_matrix';
import Glossar from '@/models/glossar';
import { wgsNoeExtent } from '@/main/constants';
import { tags } from '@/main/tags';

export default {
  props: {
    objektClicked: {
      type: Function,
      default: () => () => {}
    }
  },
  data: () => ({
    searchString: '',
    loadingPhotonData: false,
    results: []
  }),
  mounted() {
    this.debouncedPhotonSeach = debounce(this.photonSearch, 250);
    this.debouncedTextlog = debounce(this.postTextLog, 1000);
  },
  methods: {
    updateSearchString(e) {
      if (this.searchString) {
        this.debouncedPhotonSeach();
        if (e.key !== 'Enter') {
          this.debouncedTextlog();
        }
      } else {
        this.loadingPhotonData = false;
        this.results.length = 0;
      }
    },
    clear() {
      this.results.length = 0;
    },
    async postTextLog() {
      if (this.searchString?.length) {
        const payload = {
          suchtext: this.searchString,
          logtype: this.$router.currentRoute.name === 'home' ? 'start' : 'standort'
        };
        Axios.post('/api/textlog', payload);
      }
    },
    async photonSearch() {
      const userlevel = this.$store.state.userdata?.userlevel || 0;
      // 180620002: 'Anzeigen mit Login',
      // 180620003: 'Anzeigen ohne Login',
      const allowedStatus =
        userlevel === 0
          ? [180620003]
          : this.$store.state.userdata.userlevel > 6
          ? [200000000, 180620004, 180620001, 180620002, 180620003]
          : [180620002, 180620003];
      const allObjekte = Objekt.query()
        .where(objekt => {
          return !!allowedStatus.includes(objekt.status);
        })
        .get();

      if (this.searchString.length && !isNaN(Number(this.searchString))) {
        const filteredObjekte = allObjekte.filter(o => {
          //@ts-ignore
          return o.objektnummer.toString().includes(this.searchString);
        });
        this.results = filteredObjekte.map(o => {
          return {
            //@ts-ignore
            objektnummer: o.objektnummer,
            //@ts-ignore
            id: o.id,
            //@ts-ignore
            flaechenbezeichnung: o.flaechenbezeichnung,
            //@ts-ignore
            type: o.objektart,
            searchType: 'objektnummer'
          };
        });
        return;
      }
      //"objektname" bzw. "objektname_en" und in "flaechenbezeichnung",
      // use searchstring to find objekte via objektname, objektname_en  and flaechenbezeichnung
      // each word has to be found
      const searchProps = ['flaechenbezeichnung'];
      if (this.$route.params.lang === 'en') {
        searchProps.push('objektname_en');
      } else {
        searchProps.push('objektname');
      }
      const searchWords = this.searchString.toLowerCase().replace(/\s\s+/g, ' ').split(' ');
      const filteredObjekte = [];

      const lang = this.$route.params.lang;
      const maxObjekte = this.$router.currentRoute.name === 'home' ? 5 : 15;
      for (let i = 0, ii = allObjekte.length; i < ii; i++) {
        const objekt = allObjekte[i];
        let compoundSearchableText = searchProps
          //@ts-ignore
          .map(p => objekt[p] || objekt.uebersetzung[p])
          .join('')
          .toLowerCase();

        //@ts-ignore
        if (objekt.standortbeschreibung && objekt.standortbeschreibung.length) {
          //@ts-ignore
          objekt.standortbeschreibung.forEach(id => {
            compoundSearchableText += tags.find(t => t.id === id)[lang].toLowerCase();
          });
        }
        if (searchWords.every(word => compoundSearchableText.includes(word))) {
          filteredObjekte.push(objekt);
        }
        if (filteredObjekte.length === maxObjekte) {
          break;
        }
      }

      this.loadingPhotonData = true;
      const query = router.currentRoute.query;
      let lon, lat;

      if (query.center) {
        // center is not defined if coming directly from home screen
        const center = /** @type {string} */ (query.center).split(',').map(Number);
        lon = center[0];
        lat = center[1];
      } else {
        // if nothing already in the URL, chose st. poelten for location bias
        lat = 48.20353;
        lon = 15.63817;
      }

      const layerString = ['city'].map(s => `&layer=${s}`).join(''); // city = gemeinde, no districts (= ?) and no counties (= Bezirke) by design
      const photonString = `https://photon.komoot.io/api/?q=${this.searchString}&bbox=${wgsNoeExtent}&lon=${lon}&lat=${lat}&lang=de&osm_tag=place&osm_tag=boundary${layerString}&osm_tag=value:!hamlet&limit=15`;
      try {
        const response = await Axios.get(photonString);
        const results = response.data.features;
        this.removeUnwanted(results);
        this.results.length = 0;
        if (results.length) {
          if (results.length > 2) {
            results.length = 2;
          }
          this.results.push(...results);
        }
        this.results.push(
          ...filteredObjekte.map(o => {
            return {
              //@ts-ignore
              objektnummer: o.objektnummer,
              //@ts-ignore
              id: o.id,
              //@ts-ignore
              flaechenbezeichnung: o.flaechenbezeichnung,
              //@ts-ignore
              type: o.objektart
            };
          })
        );
        const searchLowerCase = this.searchString.toLowerCase();
        if (this.$router.currentRoute.name === 'home') {
          // in the home component, also search Wissen, Förderkompass and Glossar
          const kampagnenResults = Kampagnen.query() // only german
            .where(kampagne => {
              return !!(kampagne.aktiv === true && kampagne.modul1text?.toLowerCase().includes(searchLowerCase));
            })
            .orWhere(kampagne => {
              return !!(kampagne.aktiv === true && kampagne.modul2text?.toLowerCase().includes(searchLowerCase));
            })
            .orWhere(kampagne => {
              return !!(kampagne.aktiv === true && kampagne.modul3text?.toLowerCase().includes(searchLowerCase));
            })
            .orWhere(kampagne => {
              return !!(kampagne.aktiv === true && kampagne.modul4text?.toLowerCase().includes(searchLowerCase));
            })
            .orWhere(kampagne => {
              return !!(kampagne.aktiv === true && kampagne.modul5text?.toLowerCase().includes(searchLowerCase));
            })
            .orWhere(kampagne => {
              return !!(kampagne.aktiv === true && kampagne.modul6text?.toLowerCase().includes(searchLowerCase));
            })
            .orWhere(kampagne => {
              return !!(
                kampagne.aktiv === true && kampagne.veranstaltungentext?.toLowerCase().includes(searchLowerCase)
              );
            })
            .limit(5)
            .get();

          this.results.push(
            ...kampagnenResults.map(k => {
              return {
                //@ts-ignore
                id: k.id,
                //@ts-ignore
                flaechenbezeichnung: k.header,
                //@ts-ignore
                type: 'kampagne'
              };
            })
          );

          // Förderkompass
          const langAppendix = this.$route.params.lang === 'en' ? '_en' : '_de';
          const fKompassResults = FMMatrix.query()
            .where(foerderung => {
              return !!foerderung[`name${langAppendix}`]?.toLowerCase().includes(searchLowerCase);
            })
            .orWhere(foerderung => {
              return !!foerderung[`zielgruppe${langAppendix}`]?.toLowerCase().includes(searchLowerCase);
            })
            .orWhere(foerderung => {
              return !!foerderung[`kurzbeschreibung${langAppendix}`]?.toLowerCase().includes(searchLowerCase);
            })
            .orWhere(foerderung => {
              return !!foerderung[`detailbeschreibung${langAppendix}`]?.toLowerCase().includes(searchLowerCase);
            })
            .limit(5)
            .get();
          this.results.push(
            ...fKompassResults.map(k => {
              return {
                //@ts-ignore
                id: k.id,
                //@ts-ignore
                flaechenbezeichnung: k[`name${langAppendix}`],
                //@ts-ignore
                type: 'fKompass'
              };
            })
          );

          const glossarResults = Glossar.query()
            .where(glos => {
              return !!glos[`titel${langAppendix}`]?.toLowerCase().includes(searchLowerCase);
            })
            .orWhere(glos => {
              return !!glos[`beschreibung${langAppendix}`]?.toLowerCase().includes(searchLowerCase);
            })
            .limit(5)
            .get();
          this.results.push(
            ...glossarResults.map(k => {
              return {
                //@ts-ignore
                id: k.id,
                //@ts-ignore
                flaechenbezeichnung: k[`titel${langAppendix}`],
                //@ts-ignore
                type: 'glossar'
              };
            })
          );
        }
        if (!this.results.length) {
          this.results = [{ error: 'Keine Ergebnisse' }];
        }
        this.loadingPhotonData = false;
      } catch (err) {
        this.results = [{ error: 'Fehler bei der Ortssuche' }];
        this.loadingPhotonData = false;
      }
    },

    getIconForType(type) {
      if (type === 'Feature') {
        return 'mdi-map-marker';
      } else if (type === 180620000) {
        return 'mdi-selection';
      } else if (type === 180620001) {
        return 'mdi-office-building';
      } else if (type === 180620002) {
        return 'mdi-factory';
      } else if (type === 'kampagne') {
        return 'mdi-newspaper-variant';
      } else if (type === 'fKompass') {
        return 'mdi-currency-eur';
      } else if (type === 'glossar') {
        return 'mdi-message-processing';
      }
    },

    goToResult(result) {
      if (result.objektnummer) {
        // ecoplus objekt, directly go to objekt page
        this.objektClicked(result.id);
        return;
      }

      if (result.type === 'kampagne') {
        this.$router.push(`/${this.$route.params.lang}/wissen/${result.id}`);
        return;
      }
      if (result.type === 'fKompass') {
        this.$router.push(`/${this.$route.params.lang}/foerderkompass/${result.id}`);
        return;
      }
      if (result.type === 'glossar') {
        this.$router.push(`/${this.$route.params.lang}/glossar/${result.id}`);
        return;
      }

      let extent = result.properties.extent;
      const resultText = this.getNameForResult(result);
      this.results.length = 0;
      this.searchString = resultText;

      if (!extent) {
        extent = boundingExtent([result.geometry.coordinates]);
      }
      extent = [
        Math.min(extent[0], extent[2]),
        Math.min(extent[1], extent[3]),
        Math.max(extent[0], extent[2]),
        Math.max(extent[1], extent[3])
      ];
      if (this.$router.currentRoute.name === 'home') {
        const center = getCenter(extent);
        const newQuery = Object.assign({}, this.$route.query, { zoom: 11, center: center.join(',') });
        this.$router.push({ name: 'standortsuche', query: newQuery });
      } else {
        const mercatorExtent = applyTransform(extent, getTransform('EPSG:4326', 'EPSG:3857'));
        this.$map.getView().fit(mercatorExtent, {
          padding: [50, 50, 50, 50],
          duration: 250,
          maxZoom: 15
        });
      }
    },

    /**
     * removes results that would cause duplicate names
     * these most commonly are real duplicates, like duplicate street names for the
     * same street
     * also removes manually defined unwanted entries by name
     * changes the Array in place
     */
    removeUnwanted(resultArray) {
      const usedDisplayNames = [];
      const unwantedNames = ['VAK_REL Bürogebäude'];
      for (let i = resultArray.length - 1; i >= 0; i--) {
        const result = resultArray[i];
        const name = this.getNameForResult(result);
        if (!name || usedDisplayNames.includes(name)) {
          // remove duplicate
          resultArray.splice(i, 1);
        } else if (unwantedNames.find(n => name.includes(n))) {
          // remove manually defined unwanted entry by name
          resultArray.splice(i, 1);
          // remove unwanted entries by location codes
        } else if (result.properties.countrycode !== 'AT' || result.properties.state !== 'Niederösterreich') {
          resultArray.splice(i, 1);
        } else {
          usedDisplayNames.push(name);
        }
      }
    },

    getNameForResult(result) {
      if (result.type === 'Feature') {
        // photon result
        const props = result.properties;
        if (props.name) {
          if (props.type === 'region') {
            return `${props.name} (Region)`;
          } else if (props.city || props.district || props.county) {
            return `${props.name} (${props.city || props.district || props.county})`;
          } else {
            return props.name;
          }
        }
      } else {
        // ecoplus-objekte, found via objektnummer
        if (result.searchType === 'objektnummer') {
          return `Objekt ${result.objektnummer} (${result.flaechenbezeichnung})`;
        } else {
          return result.flaechenbezeichnung;
        }
      }
    },
    goToFirst() {
      if (this.results.length && !this.results[0].error) {
        this.goToResult(this.results[0]);
      }
    }
  }
};
</script>

<style scoped>
.searchPlaces {
  background-color: white;
  margin-bottom: 0px;
  padding-top: 0px;
  border-radius: 0px;
}

.fullWidth {
  width: 100%;
}

.raised {
  z-index: 100;
}

.transparent {
  background-color: transparent !important;
}

.resultBox {
  max-height: 200px;
  overflow-y: scroll;
}

/** to handle smooth animations and single-entry*/
.resultInnerBox {
  min-height: 35px;
}
</style>
