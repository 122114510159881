import { Model } from '@vuex-orm/core';

export default class Ort extends Model {
  static entity = 'ort';
  static fields() {
    return {
      id: this.string(''),
      name: this.string(''),
      bezirk: this.string(''),
      region: this.string('')
    };
  }
}
