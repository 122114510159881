//import Widmung from '@/models/widmung';
import store from '@/store';
import { toLonLat } from 'ol/proj';
import i18n from '../localization/i18n';

/**
 * get the formatted center to be used in routes
 * @param {Array<number>} center Array of coordinates
 * @returns {string}
 */
export function getFormattedCenter(center) {
  return center.map(n => n.toFixed(4).replace(/\.?0+$/, '')).join(',');
}

/**
 * get the formatted center to be used in routes from an objekt-feature
 * @param {*} feature
 * returns {string}
 */
export function getFormattedCenterFromFeature(feature) {
  const geom = feature
    .getGeometry()
    .getGeometriesArray()
    .find(geom => geom.getType() === 'Point');
  const lonLat = toLonLat(geom.getCoordinates());
  return getFormattedCenter(lonLat);
}

export const flaecheItems = {
  // Grundstück
  180620000: [
    { value: 0, text: '0 - 3.000 m²', min: 0, max: 3000 },
    { value: 1, text: '3.001 - 10.000 m²', min: 3000, max: 10000 },
    { value: 2, text: '10.001 - 30.000 m²', min: 10000, max: 30000 },
    { value: 3, text: '30.001 - 50.000 m²', min: 30000, max: 50000 },
    { value: 4, text: '> 50.000', min: 50000, max: Infinity }
  ],
  // Büro
  180620001: [
    { value: 0, text: '0 - 50 m²', min: 0, max: 50 },
    { value: 1, text: '50,1 - 100 m²', min: 50, max: 100 },
    { value: 2, text: '100,1 - 300 m²', min: 100, max: 300 },
    { value: 3, text: '300,1 - 1000 m²', min: 300, max: 1000 },
    { value: 4, text: '> 1000', min: 1000, max: Infinity }
  ],
  // Gewerbeobjekt
  180620002: [
    { value: 0, text: '0 - 200 m²', min: 0, max: 200 },
    { value: 1, text: '200,1 - 500 m²', min: 200, max: 500 },
    { value: 2, text: '500,1 - 1000 m²', min: 500.1, max: 1000 },
    { value: 3, text: '1000,1 - 2000 m²', min: 1000, max: 2000 },
    { value: 4, text: '> 2000', min: 2000, max: Infinity }
  ]
};

export const preisItems = {
  // Grundstück
  180620000: {
    kauf: [
      { value: 0, text: 'keine Angabe', min: 0, max: 0 },
      { value: 1, text: '0 - € 30', min: 0.01, max: 30 },
      { value: 2, text: '€ 30,1 - € 50', min: 30, max: 50 },
      { value: 3, text: '€ 50,1 - € 100', min: 50, max: 100 },
      { value: 4, text: '€100,1 - € 150', min: 100, max: 150 },
      { value: 5, text: '> € 150', min: 150, max: Infinity }
    ],
    miete: [
      { value: 0, text: 'keine Angabe', min: 0, max: 0 },
      { value: 1, text: '0 - € 0,8', min: 0.01, max: 0.8 },
      { value: 2, text: '€ 0,81 - € 1', min: 0.8, max: 1 },
      { value: 3, text: '€ 1,01 - € 1,2', min: 1, max: 1.2 },
      { value: 4, text: '> € 1,2', min: 1.2, max: Infinity }
    ]
  },
  // Büro
  180620001: {
    kauf: null,
    miete: [
      { value: 0, text: 'keine Angabe', min: 0, max: 0 },
      { value: 1, text: '0 - € 8', min: 0.01, max: 8 },
      { value: 2, text: '€ 8,1 - € 12', min: 8, max: 12 },
      { value: 3, text: '€ 12,1 - € 15', min: 12, max: 15 },
      { value: 4, text: '> € 15', min: 15, max: Infinity }
    ]
  },
  // Gewerbeobjekt
  180620002: {
    kauf: null,
    miete: [
      { value: 0, text: 'keine Angabe', min: 0, max: 0 },
      { value: 1, text: '0 - € 5', min: 0.01, max: 5 },
      { value: 2, text: '€ 5,1 - € 7', min: 5, max: 7 },
      { value: 3, text: '€ 7,1 - € 10', min: 7, max: 10 },
      { value: 4, text: '> € 10', min: 10, max: Infinity }
    ]
  }
};

export function featureMeetsKaufMietSelection(feature) {
  const mieteOrKauf = store.state.mieteOrKauf;
  if (!mieteOrKauf) {
    return true;
  }
  if (mieteOrKauf === 'kauf' && feature.get('istkaufobjekt')) {
    return true;
  }
  if (mieteOrKauf === 'miete' && feature.get('istmietobjekt')) {
    return true;
  }
  return false;
}

export function featureMeetsFlaecheSelection(feature) {
  const selectedType = store.state.selectedType;
  if (!selectedType) {
    return true;
  }
  const selection = store.state.selectedFlaeche;
  const featureFlaeche = feature.get('flaecheinqm');
  for (let i = 0; i < selection.length; i++) {
    const thisSelection = selection[i];
    const selectionMin = flaecheItems[selectedType][thisSelection].min;
    const selectionMax = flaecheItems[selectedType][thisSelection].max;
    if (featureFlaeche >= selectionMin && featureFlaeche <= selectionMax) {
      return true;
    }
    if (feature.get('istteilbar')) {
      const kleinsteFläche = feature.get('kleinsteflaecheinqm');
      if (kleinsteFläche >= selectionMin && kleinsteFläche <= selectionMax) {
        return true;
      }
    }
  }
  return false;
}

export function featureMeetsTagsSelection(feature) {
  const selectedTag = store.state.selectedTag;
  if (!selectedTag) {
    return true;
  }
  const featureTags = feature.get('standortbeschreibung');
  if (featureTags) {
    return featureTags.includes(selectedTag);
  }
  return false;
}

export function featureMeetsPriceSelection(feature) {
  const selectedType = store.state.selectedType;
  if (!selectedType) {
    return true;
  }
  const mieteOrKauf = store.state.mieteOrKauf;
  if (!mieteOrKauf) {
    return true;
  }

  // null for Kaufpreis of Buero / Grundstueck
  if (!preisItems[selectedType][mieteOrKauf]) {
    return true;
  }

  const selection = store.state.selectedPreis;
  const featurePrice = mieteOrKauf === 'miete' ? feature.get('mietpreisproqm') : feature.get('kaufpreisproqm');
  for (let i = 0; i < selection.length; i++) {
    const thisSelection = selection[i];
    const selectionMin = preisItems[selectedType][mieteOrKauf][thisSelection].min;
    const selectionMax = preisItems[selectedType][mieteOrKauf][thisSelection].max;
    if (featurePrice >= selectionMin && featurePrice <= selectionMax) {
      return true;
    }
  }
  return false;
}

export function featureMeetsEcoplusOnlySelection(feature) {
  return store.state.ecoplusOnly ? feature.get('ecoplus') || feature.get('ecoplus').anzeigen : true;
}

export function featureMeetsFavsOnlySelection(feature) {
  if (store.state.favsOnly) {
    const favs = store.state.userdata.favoriten;
    if (favs.includes(`objekt.${feature.getId()}`)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function featureMeetsOnlyPropertiesSelection(feature) {
  if (store.state.onlyProperties) {
    if (feature.get('owner') == store.state.userdata.id) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export function featureMeetsStatusSelection(feature) {
  const selectedStatus = store.state.selectedStatus;
  return selectedStatus.includes(feature.get('status'));
}

/**
 * get the formatted area
 * @param {number} value area in square meters
 * @returns {string}
 */
export function getFormattedAreaString(value) {
  return value >= 100000
    ? (value / 1000000).toLocaleString('de', { maximumFractionDigits: 2 }) + ' km²'
    : value.toLocaleString('de', { maximumFractionDigits: 2 }) + ' m²';
}

export function getFormattedPrice(value) {
  return value.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
}

/**
 *
 * @param {number} flaecheinqm
 * @param {boolean} istteilbar
 * @param {number} kleinsteflaecheinqm
 * @returns string
 */
export function getFormattedSize(flaecheinqm, istteilbar, kleinsteflaecheinqm) {
  let areaText = `${getFormattedAreaString(flaecheinqm)}`;
  if (istteilbar) {
    if (kleinsteflaecheinqm) {
      areaText += ` (${i18n.tc('expose.smallestArea')}: ${getFormattedAreaString(kleinsteflaecheinqm)})`;
    } else {
      if (flaecheinqm) {
        areaText += ` (${i18n.tc('objekt.divisible')})`;
      } else {
        areaText = i18n.tc('objekt.divisible');
      }
    }
  }
  return areaText;
}

/**
 *
 * @param {number} priceTotal
 * @param {number} pricePerQm
 * @param {number} area
 * @param {boolean} istteilbar
 * @param {number} kleinsteflaecheinqm
 * @returns string
 */
export function getFormattedPriceText(priceTotal, pricePerQm, area, istteilbar, kleinsteflaecheinqm) {
  if (!priceTotal && !pricePerQm) {
    return i18n.tc('objekt.uponRequest');
  }
  const calculatedPriceTotal = priceTotal || pricePerQm * area;
  const calculatedPricePerQm = pricePerQm || priceTotal / area;

  if (istteilbar) {
    if (!kleinsteflaecheinqm) {
      return `${getFormattedPrice(calculatedPricePerQm)} ${i18n.tc('expose.per')} m²`;
    }
    return `${i18n.tc('label.from')} ${getFormattedPrice(
      calculatedPricePerQm * kleinsteflaecheinqm
    )} (${getFormattedPrice(calculatedPricePerQm)} ${i18n.tc('expose.per')} m²)`;
  }

  return `${getFormattedPrice(calculatedPriceTotal)} (${getFormattedPrice(calculatedPricePerQm)} ${i18n.tc(
    'expose.per'
  )} m²)`;
}
