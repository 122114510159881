import { Model } from '@vuex-orm/core';

export default class FMMatrix extends Model {
  static entity = 'fm_matrix';
  static primaryKey = 'id';
  static fields() {
    return {
      id: this.string(''),
      name_de: this.string('').nullable(),
      foerderstelle: this.string('').nullable(),
      foerderstelleid: this.string('').nullable(),
      zielgruppe_de: this.string('').nullable(),
      kurzbeschreibung_de: this.string('').nullable(),
      detailbeschreibung_de: this.string('').nullable(),
      investitionenvon: this.number(0).nullable(),
      investitionenbis: this.number(0).nullable(),
      unternehmensgroessen: this.attr([]).nullable(),
      vorhaben: this.attr([]).nullable(),
      foerderarten: this.attr([]).nullable(),
      schwerpunkte: this.attr([]).nullable(),
      sortierung: this.number(0).nullable(),
      url: this.string('').nullable(),
      name_en: this.string('').nullable(),
      zielgruppe_en: this.string('').nullable(),
      kurzbeschreibung_en: this.string('').nullable(),
      detailbeschreibung_en: this.string('').nullable()
    };
  }
}
