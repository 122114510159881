import TileLayer from 'ol/layer/Tile';
import XYZSource from 'ol/source/XYZ';
import { createXYZ } from 'ol/tilegrid';
import TileGrid from 'ol/tilegrid/TileGrid';
import { Vector as VectorSource, XYZ } from 'ol/source';
import { Group, Vector as VectorLayer } from 'ol/layer';
import { GeoJSON } from 'ol/format';
import { Stroke, Style } from 'ol/style';
import { switchResolution } from './constants';
import { objektStyle } from '@/main/style';
import { transformExtent } from 'ol/proj';

const bmapAttribution =
  '<a href="http://www.basemap.at">basemap.at</a> &copy; <a href="http://creativecommons.org/licenses/by/3.0/at/">CC BY 3.0 AT</a>';
const bmapExtent = [977650, 5838030, 1913530, 6281290];

const bmapTilegrid = new TileGrid({
  extent: bmapExtent,
  origin: [-20037508.3428, 20037508.3428],
  resolutions: createXYZ({
    maxZoom: 18
  }).getResolutions()
});

export const stamenExtent = transformExtent([-3, 41, 24, 52], 'EPSG:4326', 'EPSG:3857');

/**
 * @returns {import("ol/layer/Group").default}
 */
export function createOrthoGroup(options = {}) {
  const orthoLayer = new TileLayer({
    source: new XYZSource(
      Object.assign(
        {
          attributions: bmapAttribution,
          crossOrigin: 'anonymous',
          tileGrid: bmapTilegrid,
          url: 'https://neu{1-4}.mapserver.at/mapproxy/wmts/bmaporthofoto30cm/normal/google3857/{z}/{y}/{x}.jpeg'
        },
        options.sourceOptions
      )
    )
  });

  const orthoOverLays = new TileLayer({
    source: new XYZSource(
      Object.assign(
        {
          attributions: bmapAttribution,
          crossOrigin: 'anonymous',
          tileGrid: bmapTilegrid,
          url: 'https://neu{1-4}.mapserver.at/mapproxy/wmts/bmapoverlay/normal/google3857/{z}/{y}/{x}.png'
        },
        options.sourceOptions
      )
    )
  });

  return new Group({
    maxResolution: switchResolution,
    layers: [orthoLayer, orthoOverLays]
  });
}

/**
 * @returns {import("ol/source/XYZ").default}
 */
export function createBmapSource(options) {
  return new XYZSource(
    Object.assign(
      {
        attributions: bmapAttribution,
        crossOrigin: 'anonymous',
        tileGrid: bmapTilegrid,
        url: 'https://neu{1-4}.mapserver.at/mapproxy/wmts/geolandbasemap/normal/google3857/{z}/{y}/{x}.png'
      },
      options
    )
  );
}

/**
 * @returns {import("ol/source/XYZ").default}
 */
export function createBmapGrauSource(options) {
  return new XYZSource(
    Object.assign(
      {
        attributions: bmapAttribution,
        crossOrigin: 'anonymous',
        tileGrid: bmapTilegrid,
        url: 'https://neu{1-4}.mapserver.at/mapproxy/wmts/bmapgrau/normal/google3857/{z}/{y}/{x}.png'
      },
      options
    )
  );
}

/**
 * create a stamen background source
 * @param {Object} [options]
 * @returns {import("ol/source/XYZ").default}
 */
export function createStamenSource(options) {
  return new XYZ({
    url: 'https://neu{1-4}.mapserver.at/mapproxy/wmts/toner/toner/google3857/{z}/{y}/{x}.png',
    maxZoom: 13,
    ...options
  });
}

/**
 * returns all needed Layers.
 * each single layer has a unique name:
 * [bmap, orthofoto]
 * @returns {Array<import("ol/layer/Layer").default | import("ol/layer/Group").default>}
 */
export function createAllLayers() {
  const stamenToner = new TileLayer({
    extent: stamenExtent,
    source: createStamenSource()
  });
  stamenToner.set('name', 'stamen');

  const bmapLayer = new TileLayer({
    minResolution: switchResolution,
    source: createBmapSource()
  });
  bmapLayer.set('name', 'bmap');

  const orthoGroup = createOrthoGroup();

  orthoGroup.set('name', 'orthofoto');

  const geoJsonFormat = new GeoJSON({
    featureProjection: 'EPSG:3857'
  });

  const stateBorders = new VectorLayer({
    //@ts-ignore
    name: 'bgldBorders',
    source: new VectorSource({
      format: geoJsonFormat,
      url: '/geodata/viertel_merged.json'
    }),
    style: new Style({
      stroke: new Stroke({
        width: 3.5,
        color: 'rgb(0,90,154)'
      })
    }),
    opacity: 0.5
  });

  const regionBorders = new VectorLayer({
    //@ts-ignore
    name: 'regions',
    source: new VectorSource({
      format: geoJsonFormat,
      url: '/geodata/viertel_simplified.geojson'
    }),
    style: new Style({
      stroke: new Stroke({
        width: 2.5,
        color: 'rgb(0,90,154)'
      })
    }),
    opacity: 0.5
  });

  const bordersGroup = new Group({
    layers: [stateBorders, regionBorders]
  });
  bordersGroup.set('name', 'borders');

  return [stamenToner, bmapLayer, orthoGroup, bordersGroup];
}

/**
 * new instance of objekt layer
 * @returns {import("ol/layer/Vector").default}
 */
export function createObjektLayer() {
  return new VectorLayer({
    zIndex: 2,
    //@ts-ignore
    name: 'objektLayer',
    updateWhileAnimating: true,
    source: new VectorSource(),
    style: (feature, resolution) => {
      return objektStyle(feature, resolution, false);
    }
  });
}
